<template>
    <v-row class="">
        <!-- not modal -->
        <v-col sm="12" cols="12" class="pt-0">
            <h2 class="font_20 mb-0">{{ $t('customer') }}</h2>
            <p class="mb-0">{{ $t("description") }}</p>
            <v-row>
                <v-col sm="12" cols="12">
                    <template>
                        <v-simple-table class="">
                            <template>
                                <tbody>
                                <tr>
                                    <td class="text-bold">{{ $t('customer_type') }}</td>
                                    <td colspan="2">
                                        <v-combobox
                                            v-model="c.customerType"
                                            :items="customerTypes"
                                            label="select"
                                            item-value="value.id"
                                            item-text="name"
                                            multiple
                                            chips
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    :key="data.item.id"
                                                >
                                                    <v-avatar
                                                        class="accent white--text"
                                                        left
                                                        v-text="data.item.name.slice(0, 1).toUpperCase()"
                                                    ></v-avatar>
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                        </v-combobox>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-bold">{{ $t('default_customer') }}</td>
                                    <td>{{ $t('description') }}</td>
                                    <td style="" class="primary--text align-center justify-center d-flex text-bold">
                                        <v-select
                                            class=""
                                            v-model="c.defaultCustomer"
                                            :items="customers"
                                            label="select"
                                            return-object
                                            item-text="name"
                                            color="primary"
                                        />
                                    </td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                </v-col>
            </v-row>

            <v-divider/>

            <v-card outlined dense class="no_border function_footer">
                <v-btn color="primary" class="float-right white--text" @click="onSaveClose">
                    {{ $t('save') }}
                </v-btn>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import CustomerSettingModel from "@/scripts/model/pos/CustomerSetting"

const posHandler = require("@/scripts/posHandler")
const customerTypeHandler = require("@/scripts/customerTypeHandler")
const customerHandler = require("@/scripts/customerHandler")
export default {

    components: {},
    data: () => ({
        c: new CustomerSettingModel({}),
        customerTypes: [],
        customers: []
    }),
    methods: {
        async onSaveClose() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    posHandler.customerSettingPost(new CustomerSettingModel(this.c)).then(response => {
                        if (response.data.statusCode === 201) {
                            const res = response.data.data
                            this.c = res
                            this.$snotify.success('Update Successfully')
                            // this.$refs.form.reset()
                        }
                    }).catch(e => {
                        this.$snotify.error('Something went wrong')
                        this.errors.push(e)
                    })
                }, 300);
            })
        },
        async loadCustomerSetting() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    posHandler.customerSettingGet().then(res => {
                        if (res.data.statusCode === 200) {
                            const data = res.data.data
                            if (data.length > 0) {
                                this.c = data[0]
                            }
                        }
                    })
                }, 300)
            })
        },
        async loadCustomerType() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    customerTypeHandler.get().then((res) => {
                        this.customerTypes = res.filter((m) => {
                            return m.nature.name.includes("Individual");
                        });
                    });
                }, 300);
            });
        },
        async loadCustomer() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true;
                    customerHandler.list().then((res) => {
                        if (res.data.statusCode === 200) {
                            this.showLoading = false;
                            this.customers = res.data.data;
                        }
                    });
                }, 300);
            });
        },
    },
    mounted: async function () {
        await this.loadCustomer()
        await this.loadCustomerType()
        await this.loadCustomerSetting()
    }
}
;
</script>
<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child > td:not(.v-data-table__mobile-row) {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.btn_save {
    color: #ffffff;
    text-transform: capitalize;
}

.w_100 {
    width: 100px !important;
}

.float-right {
    margin-top: 0px !important;
}

.float-left {
    margin-top: 0px !important;

}

.v-input--switch {
    margin-top: 0px;
    height: 30px;
}

.attachment_table .v-data-table__wrapper {
    border-bottom: none !important;
}


</style>